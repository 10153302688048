@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(../src/fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(../src/fonts/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Light'), url(../src/fonts/Gilroy-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Gilroy-Medium' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  background: rgb(240, 244, 249);
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-control-attribution,
.leaflet-control-easyPrint {
  display: none !important
}

.map-container {
  filter: blur(1.5px);
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  position: absolute !important;
  bottom: 30% !important;
  left: 0 !important;
  z-index: 1000 !important;
  background-color: white !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.MuiTooltip-tooltip {
  padding: 10px !important;
  border: 1px solid;
  background-color: rgb(26, 26, 26) !important;
}

#dateText {
  font-size: 0.99rem !important
}


::-webkit-scrollbar {
  width: 5px;
  height: 0px
}

::-webkit-scrollbar-track {
  border-radius: 20px
}

::-webkit-scrollbar-thumb {
  background: #28282b;
  border-radius: 20px
}

::-webkit-scrollbar-thumb:hover {
  background: #747474
}

.recharts-default-tooltip {
  margin: 0px !important;
  padding: 20px !important;
  background-color: rgb(240, 244, 249) !important;
  border: 1px solid rgb(240, 244, 249)!important;
  white-space: nowrap !important;
  border-radius: 10px !important;
}

.recharts-tooltip-label {
  margin: 0px !important;
  background-color: rgba(113, 247, 159, 0.1) !important;
  border-radius: 2px !important;
  padding: 10px !important;
  width: 150px !important;
}

.leaflet-geosearch-bar {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.leaflet-touch .leaflet-geosearch-bar form {
  padding: 8px;
  border-radius: 10px;
}

.reset {
  display: none !important;
}

.leaflet-left {
  left: 0% !important;
  z-index: 9999999999 !important;
}

.leaflet-top {
  top: 14% !important;
}

/* Responsive styles for screens less than 900px width */
@media (max-width: 900px) {
  .leaflet-left {
    left: auto !important;
    right: 0% !important;
  }

  .leaflet-top {
    top: 10% !important; /* Adjust this value based on your preference */
  }
}

/* Additional responsive styles for smaller screens if needed */
@media (max-width: 600px) {
  .leaflet-top {
    top: 0% !important; /* Adjust this value based on your preference */
  }
}

.customFab {
  border-radius: 2px !important;
}

.leaflet-pm-toolbar .leaflet-pm-icon-polygon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20' fit='' height='100%' width='100%' preserveAspectRatio='xMidYMid meet' focusable='false'%3E%3Cdefs%3E%3Cpath id='A' d='M43.496 505.323H28.498v-6.656h1.666v3.328h1.667v-3.328h1.666v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667zm0-8.323h-15a1.67 1.67 0 0 0-1.666 1.667v6.666c0 .917.75 1.667 1.667 1.667h15a1.67 1.67 0 0 0 1.666-1.667v-6.666A1.67 1.67 0 0 0 43.497 497z'%3E%3C/path%3E%3C/defs%3E%3Cuse xlink:href='%23A' transform='rotate(315 -570.897 287.385)'%3E%3C/use%3E%3C/svg%3E") !important;

}

.button-container {
  /* background-color: '#28282b' !important;
  filter: invert(100%) brightness(1000%) !important;   */
}

.leaflet-pm-icon-delete {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='60%' height='60%' viewBox='0 0 12 12' fit='' preserveAspectRatio='xMidYMid meet' focusable='false'%3E%3Cdefs%3E%3Cpath id='flyca' d='M1847.837 163.345l-1.175-1.175-4.659 4.658-4.658-4.658-1.175 1.175 4.658 4.658-4.658 4.659 1.175 1.175 4.658-4.659 4.659 4.659 1.175-1.175-4.659-4.659z'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cg transform='translate(-1836 -162)'%3E%3Cuse xlink:href='%23flyca'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  width: 75% !important;
  height: 100% !important;
  margin: auto !important;
}

.cwr-container {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  /* transform: translateY(100%); */
}

.cwr-container .slide-up {
  transform: translateY(0);
}

.cwr-container .slide-down {
  transform: translateY(100%);
}
.text-tableblackgreyHover{
  color: #333;
}

.text-tableblackgreyHover:hover{
  color: #000;
}
b, a, .MuiTypography-h5,.MuiTypography-h6,.MuiTypography-subtitle1, p,strong, div,select,option{
  color: #333 !important;
  font-family: 'Gilroy-Medium' !important;
}

.ant-typography, .MuiInputBase-root, .MuiButton-root , .MuiTypography-body1{
  font-family: 'Gilroy-Medium' !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused, .MuiFormLabel-root, .MuiInputLabel-root{
  color: #333 !important;
  font-family: 'Gilroy-Medium' !important;
}


#monitoringBtn:hover > b {
  color: #fff !important;
}

.glass::placeholder {
  color: #333 !important;  
  opacity: 1;  
}
.MuiTooltip-tooltip,.css-6hp17o-MuiList-root-MuiMenu-list{
  color: #333 !important;
  font-family: 'Gilroy-Medium' !important;
  background-color: rgb(240, 244, 249) !important;

}

:where(.css-dev-only-do-not-override-pr0fja).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000 !important;
  transform: scale(1.09); 
  transition: transform 0.2s ease-in-out;  
}

.css-i4bv87-MuiSvgIcon-root{
  fill: #333 !important;  
}

.Toastify__toast-theme--dark{
  background-color: #fff !important;
}
.Toastify__close-button{
  color: #333 !important;
}

.loginSlides{
  color: rgb(240, 244, 249) !important;
}

.white-text {
  color: white !important;
}

#learn_more{
  background: white;
  padding: 10px;
  border-radius: 8px;
  float: right;
  font-family: 'Gilroy-Bold' !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative; /* Ensures it moves independently */
  z-index: 10; /* Keeps it above other elements */
}

#contact_us{
  background: white;
  padding: 10px;
  border-radius: 8px;
  float: right;
  color: #333;
  font-family: 'Gilroy-Bold' !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative; /* Ensures it moves independently */
  z-index: 10; /* Keeps it above other elements */
}


#contact_us:hover {
  transform: translateY(-10px); /* Moves the element 10px upwards */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Enhances shadow for visibility */
  z-index: 100; /* Ensures it stays on top */
}



#learn_more{
  background: rgb(242, 126, 48);
  padding: 10px;
  border-radius: 8px;
  float: right;
  color: white !important;
  font-family: 'Gilroy-Bold' !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative; /* Ensures it moves independently */
  z-index: 10; /* Keeps it above other elements */
}

#learn_more:hover {
  transform: translateY(-10px); /* Moves the element 10px upwards */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Enhances shadow for visibility */
  z-index: 100; /* Ensures it stays on top */
}

#btnProceed{
  cursor: pointer;
  z-index: 10; /* Keeps it above other elements */
}

#btnProceed:hover {
  transform: translateY(-3px); /* Moves the element 10px upwards */
  box-shadow: 0px 4px 6px rgb(240, 244, 249); /* Enhances shadow for visibility */
  z-index: 100; /* Ensures it stays on top */
}
#getItNow{
 
}

#checkIconServices{
  color: #69f0ae !important;
  margin-right: 10px;
  
}
.serviceLevelPage{
  color: white !important;
}



@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

 

.mainServiceHeader{
  
  background-size: cover !important;
}