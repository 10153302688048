.MuiPickersPopper-root {
    z-index: 999999999999999999 !important;
  }

  .css-1vooibu-MuiSvgIcon-root .css-1vooibu-MuiSvgIcon-root{
    fill:black !important;
  }

  .css-1vooibu-MuiSvgIcon-root{
    fill:black !important;
  }


  .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon{
    fill:black !important;
  }
    .cs-message-list {
      height: 50vh !important;
    }

  .cs-message__content{
    color: #333 !important;
    background-color: #fff !important;
    border-radius: 0 .7em .7em 0 !important;
  }  
  .cs-message-list__scroll-wrapper{
    background: rgb(240, 244, 249);
  }
  
  .cs-message-separator{
    background: rgb(240, 244, 249) !important;
    color: white !important;
  }
  .cs-message-input{
    background: rgb(240, 244, 249) !important;
  }

  .cs-message-input__content-editor {
    color: #333 !important;
    background-color: rgb(240, 244, 249) !important;
  }

  .cs-chat-container .cs-message-input{
    color: #333 !important;
    background-color: rgb(240, 244, 249) !important;
  }

  .cs-message-input__content-editor-wrapper{
    color: #333 !important;
    background-color: rgb(240, 244, 249) !important;
  }

  .cs-message-input__content-editor[data-placeholder]:empty:before{
    color: #333 !important;
  }

  .cs-conversation-header{
    background-color: rgb(240, 244, 249) !important;
  }
  .cs-conversation-header__user-name{
    background-color: rgb(240, 244, 249) !important;
    color: #333 !important;
  }
  .cs-conversation-header__info{
    background-color: rgb(240, 244, 249) !important;
    color: #333 !important;
  }
  .cs-main-container{
    border-radius: 10px !important;
    border: solid 1px rgb(240, 244, 249) !important;
  }

  .cs-typing-indicator{
    background-color: rgb(240, 244, 249) !important;
    color: #333 !important;
  }
  .cs-typing-indicator__text{
    color: #333 !important;
  }
  .cs-typing-indicator__dot{
    background-color:'#fff'  !important;
  }

 .cs-button--send{
  color: #333 !important;
 }
