@font-face {
  font-family: 'Jameel Noori Nastaleeq Regular';
  src: url('../../public/fonts/urdu.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.leaflet-interactive {
  stroke: white;
  stroke-width: 1px;

  /* fill-opacity: 0.1; */
  /* fill: #ffffff00; */
}

.results {
  background: #fff !important;
  color: #333;
}

.leaflet-touch .leaflet-geosearch-bar form {
  background: #fff;
}

.glass {
  background: #fff;
  color: #333;
  font-family: 'Gilroy-Medium' !important;
}

.leaflet-control-geosearch .results> :hover {
  background-color: #fff;
  border-color: #fff;
}

.leaflet-touch .leaflet-bar a {
  width: 33px;
  height: 33px;
}

.leaflet-bar a {
  border-bottom: none;

}

.glass::placeholder {
  color: #fff;
}


.leaflet-touch .leaflet-bar {
  border: 0px solid rgba(0, 0, 0, 0.2);
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  color: #fff;
}

.leaflet-touch .leaflet-bar a:last-child {
  background: #fff;
  color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.leaflet-left {
  z-index: 1000 !important;
}

.leaflet-popup-tip-container {
  display: none !important;

}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #28282b !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.leaflet-popup-close-button {
  display: none !important;

}