@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

.main {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://escan-systems.com/static/media/service_2.b666bfdee196a8e0b0cb.png);
  background-position: top top;
  background-size: cover;
  margin: 0;

}

.inner-main {
  /* position: fixed; */
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background: 
      linear-gradient(0deg, rgb(39, 39, 39) 0%, rgb(29, 29, 29, 0.84) 50%, rgba(10, 10, 10, 0.6) 100%),
      url('https://escan-systems.com/static/media/service_2.b666bfd….png');
  background-position: top top;
  background-size: cover;
  margin: 0;
  z-index: 9999; /* Adjusted to a more reasonable value */
  min-height: 100vh; /* Ensure it has height to show background */
  display: flex; /* Centering inner content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  text-align: center;
  background: rgb(82, 82, 82);
  
  background: linear-gradient(0deg, rgb(39, 39, 39) 0%, rgb(29 29 29 / 84%) 50%, rgba(10, 10, 10, 0.6) 100%);
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'roboto';
  color: white;
 
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }

  #meet{
    margin: 0 auto;
  }
}


@media (max-width: 768px) {

  #meet{
    margin: 0 auto;
  }

}
